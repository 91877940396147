<template>
    <div class="demo-split">
        <Split v-model="split">
            <template #left>
                <div class="demo-split-pane">
                    <Calendar :cell-height=cellheight />
                </div>
            </template>
            <template #right>
                <div class="demo-split-pane">
                    管理员上传中... ...
                <Space size="large">
                    <Spin size="small" />
                    <Spin />
                    <Spin size="large" />
                </Space>
                </div>
            </template>
        </Split>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                split: 0.3,
                cellheight: 70
            }
        },
    }
</script>
<style>
    .demo-split{
        height: 550px;
        border: 1px solid #dcdee2;
    }
    .demo-split-pane{
        padding: 10px;
    }
</style>
