<style lang="less">
  @import './login.less';
</style>

<template>
    <div class="index-login">
        <Login @on-submit="handleSubmit" :key="logininit">
            <UserName name="username" enter-to-submit />
            <Password name="password" enter-to-submit />
            <div class="index-auto-login">
                <Checkbox v-model="autoLogin">自动登录</Checkbox>
                <a @click="forgetpwd">忘记密码</a>
            </div>
            <Submit/>
        </Login>
        <Divider plain>其他登录方式</Divider>
        <div style="line-height: 22px; margin-top: 10px;margin: 0 auto;text-align: center;">
            <Space wrap size="large">
                <Link :to="apiurl"><Avatar src="https://www.sishisi.com/file/logo.png" /></Link>
                <Link><Avatar src="https://file.iviewui.com/admin-plus-dist/img/icon-social-wechat.c69ec08c.svg"/></Link>
                <Link><Avatar icon="logo-github" style="background-color: #87d068" /></Link>
            </Space>
        </div>
    </div>
</template>
<script>
    import { Link } from 'view-ui-plus';
    import { mapActions } from 'vuex';
    export default {
        data () {
            return {
                autoLogin: true,
                apiurl: process.env.VUE_APP_APIURL + '/login/access'
            }
        },
        props: ['logininit'],
        methods: {
            ...mapActions([
            'handleLogin',
            'getUserInfo'
            ]),
            handleSubmit (valid, { username, password }) {
                if (valid) {
                    this.handleLogin({ username, password }).then(res => {
                        this.getUserInfo().then(res => {
                            this.$Message.success('登录成功');
                            this.$emit('on-updatemodal');
                            this.$router.push({
                                // 到首页 TODO当前页
                                name: this.$config.homeName
                            });
                        }).catch(err => {
                            if(err.response == undefined){
                                alert('服务异常['+err+']，请联系管理员');
                                reject(err)
                                return false;
                            }
                            if (err.response.status == 400){
                                const data = err.response.data;
                                this.$Message.error(data.error);
                                return false;
                            }
                        });
                    })
                }
            },
            forgetpwd () {
                this.$Message.info("再想想！");
            }
        },
        mounted() {
          // 在组件挂载后改变背景色
          document.body.style.backgroundColor = '#030404'; // 将背景色改为黑色
        }
    }
</script>
<style>
    .index-login{
        width: 400px;
        margin: 10% auto !important;
    }
    .index-auto-login{
        margin-bottom: 24px;
        text-align: left;
    }
    .index-auto-login a{
        float: right;
    }
</style>

