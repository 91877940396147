<template>
    <div>
      <hot></hot>
    </div>
</template>
<script>
    import hot from '@/views/news/hot'
    export default {
      data () {
            return {
            }
        },
        components: {
            hot
        }
    }
</script>

