import { setToken, getToken } from '../../util/libsutils'
import { login, logout, getUserInfo, loginByIAM } from '@/api/Login';
import { getBusiness } from '@/api/business';

export default {
  state: {
    userName: '',
    userId: localStorage.getItem("userId") || '',
    avatarImgPath: localStorage.getItem("avatarImgPath") || '',
    token: getToken(),
    access: '',
    hasGetInfo: false,
    unreadCount: 0,
    messageUnreadList: [],
    messageReadedList: [],
    messageTrashList: [],
    messageContentStore: {}
  },
  mutations: {
    setAvatar (state, avatarPath) {
      state.avatarImgPath = avatarPath
      localStorage.setItem("avatarImgPath", avatarPath);
    },
    setUserId (state, id) {
      state.userId = id
      localStorage.setItem("userId", id);
    },
    setUserName (state, name) {
      state.userName = name
    },
    setAccess (state, access) {
      state.access = access
    },
    setToken (state, token) {
      state.token = token
      setToken(token)
    },
    setHasGetInfo (state, status) {
      state.hasGetInfo = status
    },
    setMessageCount (state, count) {
      state.unreadCount = count
    },
    setMessageUnreadList (state, list) {
      state.messageUnreadList = list
    },
    setMessageReadedList (state, list) {
      state.messageReadedList = list
    },
    setMessageTrashList (state, list) {
      state.messageTrashList = list
    },
    updateMessageContentStore (state, { msg_id, content }) {
      state.messageContentStore[msg_id] = content
    },
    moveMsg (state, { from, to, msg_id }) {
      const index = state[from].findIndex(_ => _.msg_id === msg_id)
      const msgItem = state[from].splice(index, 1)[0]
      msgItem.loading = false
      state[to].unshift(msgItem)
    }
  },
  getters: {
    messageUnreadCount: state => state.messageUnreadList.length,
    messageReadedCount: state => state.messageReadedList.length,
    messageTrashCount: state => state.messageTrashList.length,
    getIsLogined: state => !state.userId ? false : true,
    getMessageCount: state => !state.userId ? 0 : 12,
  },
  actions: {
    // 登录
    handleLogin ({ commit }, { username, password }) {
      let params = new FormData();
      params.append('grant_type', 'authorization_password');
      params.append('username', username.trim());
      params.append('password', password.trim());
      params.append('scope', 'openid,profile,user');
      return new Promise((resolve, reject) => {
        login(params).then(res => {
          const data = res.data;
          if (data.code != undefined){
              alert(data.message)
              return false;
          }
          commit('setToken', data.access_token)
          resolve()
        }).catch(err => {
          alert('服务异常['+err+']，请联系管理员');
          reject(err)
        })
      })
    },
    // 退出登录
    handleLogOut ({ state, commit }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('setToken', '')
          commit('setAccess', [])
          commit('setUserId', '')
          resolve()
        }).catch(err => {
          reject(err)
        })
        // 如果你的退出登录无需请求接口，则可以直接使用下面4行代码而无需使用logout调用接口
        // commit('setToken', '')
        // commit('setAccess', [])
        // commit('setUserId', '')
        // resolve()
      })
    },
    // 获取用户相关信息
    getUserInfo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        try {
          getUserInfo(state.token).then(res => {
            const data = res.data
            commit('setAvatar', data.data.avatar)
            commit('setUserName', data.data.name)
            commit('setUserId', data.data.user_id)
            commit('setAccess', data.data.access)
            commit('setHasGetInfo', true)
            resolve(data)
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    // 第三方登录
    loginAccessCode ({ commit }, { code, type }) {
      return new Promise((resolve, reject) => {
        let params = new FormData();
        params.append('code', code);
        params.append('type', type);
        try {
          loginByIAM(params).then(res => {
            const data = res.data
            commit('setToken', data.access_token)
            resolve(data)
          }).catch(err => {
            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
