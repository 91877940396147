<template>
    <div class="layout">
        <Layout>
            <Header :style="{position: 'fixed', width: '100%', 'z-index': 999}">
                <Menu mode="horizontal" theme="dark" active-name="1">
                    <div class="layout-logo">
                      <router-link to="/">
                        <Image src="https://www.sishisi.com/file/logodark.png" height ="60px" style="vertical-align:top"/>
                      </router-link>
                    </div>
                    <div class="layout-nav">
                        <MenuItem name="0">
                          <Input search clearable placeholder="请输入..." class="layout-ser" @on-search="showSearchText"/>
                        </MenuItem>
                        <MenuItem name="1" to="/load">
                            <Icon type="ios-navigate"></Icon>
                            加载
                        </MenuItem>
                        <MenuItem name="2">
                            <Icon type="ios-desktop" />
                            控制台
                        </MenuItem>
                        <MenuItem name="3" @click="modal = true" v-if="!getIsLogined">
                            <Icon type="md-person" />
                            登录
                        </MenuItem>
                        <MenuItem name="4" v-if="getIsLogined"> 
                          <Dropdown transfer style="margin-left: 2px" @on-click="handleClick">
                            <Badge>
                              <Avatar :src="userAvatar" />
                            </Badge>
                            <Icon :size="18" type="md-arrow-dropdown"></Icon>
                            <template #list>
                                <DropdownMenu>
                                  <DropdownItem name="message">
                                    <Icon :size ="16" type="md-mail" />
                                      消息中心<Badge style="margin-left: 10px" :count="getMessageCount"></Badge>
                                  </DropdownItem>
                                  <DropdownItem disabled>
                                      <Icon :size ="16" type="md-settings" />
                                      设置
                                  </DropdownItem>
                                  <DropdownItem name="logout" divided>
                                      <Icon :size ="16" type="md-exit" />
                                      退出登录
                                  </DropdownItem>
                                </DropdownMenu>
                            </template>
                          </Dropdown>
                        </MenuItem>
                        <MenuItem name="5">
                            <Icon type="md-information-circle" />
                            <router-link to="/about">关于</router-link>
                        </MenuItem>
                    </div>
                </Menu>
            </Header>
            <Content :style="{margin: '88px 20px 0', background: '#fff', minHeight: '500px'}">
              <router-view/>
            </Content>
            <Footer class="layout-footer-center">              
              <GlobalFooter :links="links" :copyright="copyright" />
            </Footer>
        </Layout>
    </div>
    <Modal
        v-model="modal"
        title="登录"
        :mask-closable="false"
        footer-hide
        scrollable
        @on-ok="ok"
        @on-cancel="cancel">
        <template #header>
            <p style="color:black;text-align:center;height: inherit;line-height:35px;">
                <span style="font-size: 30px;">登录</span>
            </p>
        </template>
        <Login @on-updatemodal="updatemodal" :logininit="logininit"></Login>
  </Modal>
</template>

<script>
    import Login from '@/views/login/login'
    import { mapGetters,mapActions } from 'vuex';
    export default {
      name: 'App',
      data () {
        return {
            links: [
                {
                    key: 'iviewui',
                    // title: 'View Design',
                    icon: 'icon-view-design',
                    href: 'https://www.iviewui.com',
                    blankTarget: true
                },
                {
                    key: 'github',
                    icon: 'logo-github',
                    href: 'https://github.com/view-design/ViewUIPlus',
                    blankTarget: true
                },
                {
                    key: '条款',
                    title: '条款',
                    href: '',
                    blankTarget: true
                }
            ],
            copyright: 'Copyright © 2024 Sishisi All Rights Reserved',
            modal: false,
            logininit: true,
            userAvatar: this.$store.state.user.avatarImgPath
        }
    },
    methods: {
      showSearchText(value){
        alert(value);
      },
      ok () {
                //this.$Message.info('Clicked ok');
            },
      cancel () {
        this.logininit = !this.logininit;
          //this.$Message.info('Clicked cancel');
      },
      // 登录成功后关闭登录Modal
      updatemodal () {
        this.modal = false;
        this.logininit = !this.logininit;
        this.userAvatar = this.$store.state.user.avatarImgPath;
      },
      handleClick (name) {
        switch (name) {
          case 'logout': this.logout()
            break
          case 'message': this.message()
            break
        }
      },
      ...mapActions([
        'handleLogOut'
      ]),
      logout () {
        this.handleLogOut().then(() => {
          this.$Message.success('已退出');
          this.$router.push({
            name: this.$config.homeName
          })
        })
      },
      message () {
        this.$router.push({
          name: 'message_page'
        })
      }
    },
    mounted() {
    },
    components: {
        Login
    },
    computed: {
      ...mapGetters([
        'getMessageCount',
        'getIsLogined'
      ])
    }
}
</script>
<style>
    @import './common/style/style.css';
    .layout{
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
    }
    .layout-logo{
        /* width: 100px;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px; */
        float: left;
    }
    .layout-ser{
        width: 250px;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        float: right;
        position: relative;
        top: 15px;
        left: 20px;
    }
    .layout-nav{
        /* width: 670px; */
        margin: 0 auto;
        margin-right: 20px;
        float: right;
    }
    .layout-footer-center{
        text-align: center;
    }
  .dev-run-preview .dev-run-preview-edit{ display: none }
  /*强制改变menu */
  .ivu-menu-item-active{
    color:#e3b976 !important;
  }
  .ivu-menu-item:hover{
    color:#e3b976 !important;
  }
</style>