// 请求对象的组装
import axios from "axios";
import CryptoJS from 'crypto-js'
import { setToken, getToken } from '../libsutils'
import store from '../../store'

import {base64Str} from '../pkce'

const addErrorLog = errorInfo => {
    const { statusText, status, request: { responseURL } } = errorInfo
    let info = {
      type: 'ajax',
      code: status,
      mes: statusText,
      url: responseURL
    }
    if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info)
  }

// 导出Request类，可以用来自定义传递配置来创建实例
export class Request {
    constructor (baseUrl) {
      this.baseUrl = baseUrl
      this.queue = {}
    }
    getInsideConfig () {
      const config = {
        baseURL: this.baseUrl,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + base64Str('password-client-id:secret')
        }
      }
      return config
    }
    destroy (url) {
      delete this.queue[url]
      if (!Object.keys(this.queue).length) {
        // Spin.hide()
      }
    }
    interceptors (instance, url) {
      // 请求拦截
      instance.interceptors.request.use(config => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
  
        const token =getToken();
        // TODO
        // console.log('本地token的值： '+token);
        token && (
          config.headers={
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        );
        // 没有token  设置json拿第三方登录  TODO 还需要完善
        !token && 'oauth2/token' != url && (
          config.headers={
            'Content-Type': 'application/json'
          }
        );
        this.queue[url] = true
        return config
      }, error => {
        return Promise.reject(error)
      })
      // 响应拦截
      instance.interceptors.response.use(res => {
        this.destroy(url)
        const { data, status } = res
        return { data, status }
      }, error => {
        this.destroy(url)
        let errorInfo = error.response
        if (!errorInfo) {
          const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          }
        }
        addErrorLog(errorInfo)
        return Promise.reject(error)
      })
    }
    request (options) {
      const instance = axios.create()
      options = Object.assign(this.getInsideConfig(), options)
      this.interceptors(instance, options.url)
      return instance(options)
    }
}

// 默认导出Request实例
export default Request