

import loginRequest from '../util/http/LoginRequest'

import {base64Str} from '../util/pkce'
import { setToken } from '../util/libsutils'
/**
 * 从认证服务获取AccessToken
 * @param data 获取token入参
 * @returns 返回AccessToken对象
 */
export function getToken(data) {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': ''
    }
    if (data.client_secret) {
        // 设置客户端的basic认证
        headers.Authorization = `Basic ${base64Str(`${data.client_id}:${data.client_secret}`)}`
        // 移除入参中的key
        //delete data.client_id
        delete data.client_secret
    }
    // 清除上次的token，防止oauth2/token接口401错误，原因是Http.js会取cookie里的token来组装Authorization。那么就会进入BearerTokenAuthenticationFilter，如果token失效了，那么就会报401.
    setToken('');
    // 可以设置为AccessToken的类型
    return loginRequest.request({
        url: 'oauth2/token',
        data,
        method: 'post'
      })
    .then(response => {
        //alert(JSON.stringify(response.data));// 登录的响应结果
        setToken(response.data.access_token);// token存入
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
}

export const login = (params) => {
    setToken('');
    const data = params
    return loginRequest.request({
        url: 'oauth2/token',
        data,
        method: 'post'
    })
}
export const getUserInfo = (token) => {
    return loginRequest.request({
      url: 'demo/user-info',
      method: 'post'
    })
}

export const logout = (token) => {
    return loginRequest.request({
        url: 'demo/logout',
        method: 'post'
    })
}

export const loginByIAM = (params) => {
    const data = params
    return loginRequest.request({
        url: '/login/access/code',
        data,
        method: 'post'
    })
}